/* Hyperwave One */
@font-face {
    font-family: HyperwaveOne;
    font-weight: 500;
    src: url("../src/assets/fonts/hyperwave-font/Hyperwave.otf");
}

/* DM Sans */
@font-face {
    font-family: DMSans;
    font-weight: 600;
    src: url("../src/assets/fonts/DM_Sans/DMSans-Regular.ttf");
}

@font-face {
    font-family: DMSans;
    font-weight: 700;
    src: url("../src/assets/fonts/DM_Sans/DMSans-Medium.ttf");
}

@font-face {
    font-family: DMSans;
    font-weight: 800;
    src: url("../src/assets/fonts/DM_Sans/DMSans-Bold.ttf");
}

html {
    scroll-behavior: smooth;
}

.font-display {
    font-family: DMSans;
    font-weight: 800;
}

.handwriting {
    font-family: HyperwaveOne;
    font-weight: 500;
    --font-handwriting: HyperwaveOne;
    @apply text-red-600;
}

.dark .select {
    border-radius: 9999px !important;
    border: none !important;
}

.dark .select .select__control {
    padding: 0 10px;
}

.light.select .select__control {
    @apply !bg-custom-900;
    /* border: 1px solid #fff !important; */
}

.select {
    border: 1px solid red;
    border-radius: 9999px !important;
}

.dark .select-light {
    @apply bg-custom-900;
    /* border: 1px solid #fff !important; */
}
.dark .select-light {
    @apply bg-custom-900;
    /* border: 1px solid #fff !important; */
}


.custom-editor {
    padding: 10px;
    min-height: 200px;
    @apply bg-custom-200 text-white;
    max-height: 450px;;
}

.custom-html-style {
    /* all: unset; */
    padding: revert;
    margin: revert;
}

.custom-html-style ul,
.custom-html-style ol {
    list-style: revert;
    padding: revert;
    margin: revert;
}

.dark .card-border{
    @apply border-custom-900;
}

#lookup_field input{
    @apply h-11 focus:ring-red-600 focus-within:ring-red-600 focus-within:border-red-600 focus:border-red-600 rounded-full w-full lg:w-2/3 dark:bg-custom-100 px-5 border-none;
}

#lookup_field button {
    @apply bg-red-600 text-white border-none h-11 px-8 py-2 rounded-full w-fit;
}

#lookup_field select {
    @apply dark:bg-custom-100 text-white border-none h-11 py-2 rounded-full w-full ml-0 mt-2 px-4;
}

/* style the down chevron for select */
#lookup_field select::-ms-expand {
    display: none;
}

#lookup_field select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 15l-7-7h14l-7 7z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 1.7em top 50%, 0 0;
    background-size: 1em auto;
}